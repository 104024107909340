import React, { MouseEventHandler, useState } from 'react';

import Heading from '@components/atoms/Heading/Heading';
import { IconBoxProps } from '@data/strapi/Common/Types';
import Link from 'next/link';
import { Modal } from '../Modal';
import RichText from '@components/atoms/RichText/RichText';
import cn from 'classnames';
import styles from './IconBox.module.scss';

export default function IconBox({
  boxed,
  iconColor,
  iconPosition,
  icon,
  heading,
  description,
  url,
  disclaimer,
  className,
}: IconBoxProps) {
  const [open, setOpen] = useState<boolean>(false);

  const componentContent = (
    <>
      <div className={styles.media}>
        <i className={icon} />
        {heading && (
          <>
            {url ? (
              <div className="d-flex align-items-center" style={{gap: "8px"}}>
                <Heading {...heading} />
                <i className="icon-right"></i>
              </div>
            ) : (
              <Heading {...heading} />
            )}
          </>
        )}
        {disclaimer && (
          <i
            className={`${styles.disclaimer} icon-info`}
            onClick={() => {
              setOpen(true);
            }}
          />
        )}
      </div>
      {description && (
        <div className={styles.content}>
          <RichText
            value={
              typeof description === 'string' ? description : description.value
            }
          />
        </div>
      )}
    </>
  );
  return (
    <>
      <div
        className={cn(
          styles.iconBox,
          styles[`variation_${iconColor}`],
          iconPosition && styles[iconPosition],
          boxed && styles.boxed,
          url && styles.interactive,
          className
        )}
      >
        {url ? (
          <Link href={url}>
            {componentContent}
          </Link>
        ) : (
          <>{componentContent}</>
        )}
      </div>
      {open && disclaimer && disclaimer.value && (
        <Modal
          labelOk="Done"
          handleCancel={() => {
            setOpen(false);
          }}
          handleOk={() => {
            setOpen(false);
          }}
          className={`${styles.modal} color-gray-70`}
        >
          <RichText
            value={disclaimer.value}
            className="color-gray-70 text-start mt-4"
          />
        </Modal>
      )}
    </>
  );
}
